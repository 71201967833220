import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dev: false,
    debug: true,
    language: 'en',
    labels: null,
    api: null,
    env: 'staging',
    restricted: 'yes'
  },
  getters: {
    dev: function(state){
      return state.dev
    },
    debug: function(state){
      return state.debug
    },
    language: function(state){
      return state.language
    },
    labels: function(state){
      return state.labels
    },
    api: function(state){
      return state.api
    },
    env: function(state){
      return state.env
    },
    restricted: function(state){
        return state.restricted
    }
  },
  actions: {
    dev: function(context, payload){
      context.commit('dev',payload)
    },
    debug: function(context, payload){
      context.commit('debug',payload)
    },
    language: function(context, payload){
      context.commit('language',payload)
    },
    labels: function(context, payload){
      context.commit('labels',payload)
    },
    api: function(context, payload){
      context.commit('api',payload)
    },
    env: function(context, payload){
      context.commit('env',payload)
    },
    restricted: function(context, payload){
        context.commit('restricted', payload)
    }
  },
  mutations: {
    dev: function(state, payload){
      state.dev = payload;
    },
    debug: function(state, payload){
      state.debug = payload;
    },
    language: function(state, payload){
      state.language = payload;
    },
    labels: function(state, payload){
      state.labels = payload;
    },
    api: function(state, payload){
      state.api = payload;
    },
    env: function(state, payload){
      state.env = payload;
    },
    restricted: function(state, payload){
        state.restricted = payload
    }
  },
  modules: {
  }
})
