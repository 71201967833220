<template>
    <div>
        <!-- Button in the bottom-right corner -->
        <div class="d-flex justify-end">
            <v-btn
            @click="showChat = true"
            class="chat-button"
            icon
            large
            right
            >
            <v-icon>mdi-chat</v-icon>
            </v-btn>
        </div>

        <!-- Expanding chat window -->
        <v-dialog
            width="900px"
            v-model="showChat"
            persistent
            transition="dialog-transition"
            hide-overlay
        >
            <v-card :style="{ height: '80vh'}">
                <!-- Chat window header -->
                <v-toolbar color="primary" dark>
                    <v-toolbar-title>CUA Kidney Cancer Assistant</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showChat = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <!-- Chat body -->
                <v-list ref="chatMessages" class="chat-messages" style="height: 80%; overflow-y: auto; padding: 0 20px">
                    <v-list-item
                        v-for="(message, index) in allMessages"
                        :key="index"
                        class="message"
                    >
                        <v-list-item-content>
                            <h4 v-if="message.type == 'user'">User:</h4>
                            <h4 v-else>Assistant:</h4>
                            <v-list-item-content v-if="message.type === 'user'">{{ message.text }}</v-list-item-content>
                            <v-list-item-content v-else>
                                <div v-html="compiledMarkdown(message.text)"></div>
                            </v-list-item-content>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <!-- Input bar -->
                <v-card-actions>
                    <div class="input-area">
                        <v-text-field
                            v-model="prompt"
                            placeholder="Message Assistant"
                            append-icon="mdi-send"
                            @click:append="submitPrompt"
                            @keydown.enter.prevent="submitPrompt"
                        />
                        <!-- Microphone button -->
                        <v-btn icon :color="micButtonColor" @click="toggleVoice">
                            <v-icon v-if="isSpeaking">mdi-stop</v-icon>
                            <v-icon v-else>mdi-microphone</v-icon>
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
/*
References:
    Speech to Text / Text to Speech - https://developer.mozilla.org/en-US/docs/Web/API/Web_Speech_API/Using_the_Web_Speech_API#speech_synthesis
    Reading Stream - https://developer.mozilla.org/en-US/docs/Web/API/Streams_API/Using_readable_streams
*/
import Vue from 'vue';
import marked from 'marked';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();
recognition.continuous = false;
recognition.lang = "en-US";
recognition.interimResults = false;
recognition.maxAlternatives = 1;

const synth = window.speechSynthesis;


export default {
    name: 'ChatBot',
    props: {
    },
    data() {
        return {
            prompt: '',
            userMessages: [],
            assistantMessages: ["What can I help with?"],
            markdown: '',
            voice: null,
            showChat: false, // Controls visibility of chat window
            newMessage: '',
            micRecording: false,
            isSpeaking: false,
        }
    },
    mounted() {
        // Wait until voices are loaded
        synth.onvoiceschanged = () => {
            const voices = synth.getVoices() 
            const selectedVoice = 'Microsoft Emma Online (Natural) - English (United States)'
            this.voice = voices.find(voice => voice.name === selectedVoice);
            if (!this.voice) {
                console.error(`Voice: ${selectedVoice} not found`);
            }
        };
    },
    methods: {
        async submitPrompt() {
            this.userMessages.push(this.prompt)
            this.prompt = '';

            try {
                // Use the Fetch API for streaming the response
                // Not using self.sendRequest (axios): https://stackoverflow.com/a/60062080
                let self = this
/*                
                self.sendRequest('post',self.api+'/messageChatBot').then(function(response){
                    console.log(response)
                })
                return
*/
                const response = await fetch(self.api + '/messageChatBot', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ prompt: this.userMessages[this.userMessages.length - 1] })
                });
                if (!response.ok) {
                    // Handle the error based on the status code
                    const errorText = await response.text();
                    console.error('Error:', response.status, errorText);
                    throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
                }

                // Handle streaming
                const reader = response.body.getReader();
                const decoder = new TextDecoder('utf-8');
                let responseText = '';
                let chunk = '';
                let utterances = '';
                this.assistantMessages.push('')

                // Read the stream chunk by chunk
                // eslint-disable-next-line
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;

                    // Decode and append the received chunk to the response text
                    chunk += decoder.decode(value, { stream: true });
                    responseText += chunk
                    utterances += chunk
                    // Update assistant message with streamed response
                    Vue.set(this.assistantMessages, this.assistantMessages.length - 1, responseText)
                    
                    // Speech as soon as a sentence is streamed.
                    if (utterances.includes('.')) {
                        this.textToSpeech(utterances);
                        utterances = '';
                    }
                    chunk = '';
                }

                if (utterances) {
                    this.textToSpeech(utterances);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        },
        toggleVoice() {
            if (synth.speaking) {
                this.stopSpeach()
                this.isSpeaking = false;
            }
            else {
                this.speechToText()
            }
        },
        stopSpeach() {
            if (window.speechSynthesis) {
                window.speechSynthesis.cancel()
            }
        },
        speechToText() {
            recognition.start();
            this.micRecording = true;
            console.log("Ready to recieve speech command.");

            // Handle the result of speech recognition
            recognition.onresult = (event) => {
                this.micRecording = false;
                const speech = event.results[0][0].transcript;
                this.prompt = speech
                console.log("Speech:", speech);
                this.submitPrompt()

            };

            // Handle errors
            recognition.onerror = (event) => {
                this.micRecording = false;
                console.error(`Speech recognition error: ${event.error}`);
            };
        },
        textToSpeech(markdown) {
            const plainText = this.markdownToPlainText(markdown)
            const utterance = new SpeechSynthesisUtterance(plainText);
            utterance.voice = this.voice;
            utterance.volume = 0.5;
            utterance.rate = 1.25;
            utterance.pitch = 1.1;

            utterance.onstart = () => {
                this.isSpeaking = true;
            };

            utterance.onend = () => {
                if (!speechSynthesis.speaking && !speechSynthesis.pending) {
                    this.isSpeaking = false;
                }
            };
            synth.speak(utterance)
        },
        compiledMarkdown(text) {
            return marked(text);
        },
        markdownToPlainText(markdown) {
            const html = marked(markdown);
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = html;
            return tempDiv.textContent || tempDiv.innerText || "";
        },
        scrollToLatestMessage() {
            this.$nextTick(() => {
                const chatContainer = this.$refs.chatMessages.$el;
                chatContainer.scrollTop = chatContainer.scrollHeight;
            });
        },
    },
    computed: {
        api: function(){
            return this.$store.getters.api[this.$store.getters.env]
        },
        allMessages() {
            const combined = [];
            const length = Math.max(this.userMessages.length, this.assistantMessages.length);
            for (let i = 0; i < length; i++) {
                if (this.assistantMessages[i]) {
                    combined.push({ text: this.assistantMessages[i], type: 'assistant' });
                }
                if (this.userMessages[i]) {
                    combined.push({ text: this.userMessages[i], type: 'user' });
                }
            }
            return combined;
        },
        micButtonColor() {
            return this.micRecording ? 'primary' : 'none';
        }, 
    },
    watch: {
        userMessages() {
            this.scrollToLatestMessage();
        },
        assistantMessages() {
            this.scrollToLatestMessage();
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.input-area {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 50px;
  padding: 0 20px;
  width: 100%;
  height: 50px;
}

.message {
  border-radius: 10px;
  padding: 10px;
  margin: 5px 0;
}

</style>